



const initialState = {
    attributes:[],
    elements:[]
};

export default function attribute (state = initialState, action) {
    
    switch (action.type){
        case 'getAttributes':
            return {
                ...state,
                attributes:[...action.list]
            }
            case 'getElements':
            return {
                ...state,
                elements:[...action.list]
            }
        default:
            return state;
    }
}


